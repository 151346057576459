import Grid from "@mui/material/Grid2";
import { Alert } from "@mui/material";
import { MESSAGES as msg } from "../../utils/constants";

type color = "success" | "info" | "warning" | "error";

interface Props {
  show: boolean;
  message?: string;
  type?: color;
}

const AlertMessage = ({ show = false, message = msg.WILL_UPDATE_DATABASE, type = "warning" }: Props) => {
  return (
    <>
      {show && (
        <Grid size={12}>
          <Alert severity={type}>{message}</Alert>
        </Grid>
      )}
    </>
  );
};

export default AlertMessage;
