import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

interface Props {
  value: string;
  label: string;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void;
}
const ToggleExistingNewButtonGroup = ({ value, label, onChange }: Props) => {
  return (
    <ToggleButtonGroup fullWidth color="primary" value={value} exclusive onChange={onChange} aria-label={label}>
      <ToggleButton fullWidth value="existing">
        Existing
      </ToggleButton>
      <ToggleButton fullWidth value="new">
        New
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleExistingNewButtonGroup;
