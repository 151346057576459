const CHARACTER_LIMIT = 6;

export const MESSAGES = {
  WILL_UPDATE_DATABASE:
    "When you change this object, you will also change this for all the previously submitted requests in the database!",
  CONTROLLER:
    "All employees will by default be 'reviewer' in the CUPS app.  Tick this checkbox to give an user more rights as controller.",
  PLS_SELECT_ROW: "Please select row.",
  CHARACTER_LIMIT: CHARACTER_LIMIT,
  PASSWORD_MIN_LENGTH: `Password should be of minimum ${CHARACTER_LIMIT} characters length`,
  ERROR_PLS_TRY_AGAIN: "Something went wrong. Please try again.",
  AT_LEAST_DISTRIBUTOR: "At least one distributor must be selected",
  VALID_EMAIL: "Enter valid email",
  PLS_ENTER_VALID_NUMBER: "Please enter a valid number for",
  EXPIRATION_DATE_IS_LESS: "Expiration date must be later than Effective date",
  NO_DISTRIBUTORS_FOUND: "No distributors found for the user.",
  NO_CHANGES_DETECTED: "You haven't made any changes. Please modify the form before submitting.",
  PASSWORD_RESET_SUCCESS: "The password has been successfully reset.",
  NO_FILTER_TO_SAVE: "No filters were added to save.",
};
