import { ReactNode } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Header, Footer, AlertSnackbar } from "..";
import { ROUTE_PATHS } from "../../utils/constants";
import "./Layout.scss";

interface Props {
  children?: ReactNode;
}

const Layout = ({ children }: Props) => {
  const location = useLocation();
  const isAuthPages = location.pathname.includes(ROUTE_PATHS.AUTH);

  return (
    <Box className="layoutComponentContainer">
      <Header />
      <AlertSnackbar />
      <Box className="mainContentContainer">{children}</Box>
      {!isAuthPages && <Footer />}
    </Box>
  );
};

export default Layout;
