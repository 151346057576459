import { FC, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { DataTable, PageTitle, ContainerGrid } from "../../components";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  showModal,
  MODAL_ACTION_ADD,
  MODAL_ACTION_VIEW,
  MODAL_ACTION_EDIT,
  DATA_TABLE_ACTION_EDIT,
  DATA_TABLE_ACTION_ROW_CLICK,
  DATA_TABLE_ACTION_VIEW,
  triggerRowAction,
} from "../../store/slices";
import { generatePageClass } from "../../utils/helpers";
import { RootState } from "../../store";

import { INTERNAL } from "../../utils/constants/userRoles";

interface AddOrEditModalProps {
  handleSubmit: (formData: any) => void;
}

interface Props {
  title: string;
  dataTable: Array<any>;
  totalRows: number;
  columns: any;
  AddOrEditModal: FC<AddOrEditModalProps>;
  handleSubmit: (formData: any) => void;
  isShowNewBtn?: boolean;
  actionButton?: {
    clone?: boolean;
    edit?: boolean;
    view?: boolean;
  };
}

const CommonPageLayout = ({
  title,
  dataTable,
  totalRows,
  columns,
  AddOrEditModal,
  handleSubmit,
  isShowNewBtn = true,
  actionButton = {
    clone: false,
    edit: true,
    view: false,
  },
}: Props) => {
  const dispatch = useAppDispatch();
  const { rowAction } = useAppSelector((state: RootState) => state.dataTable);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if (rowAction) {
      const { type, data } = rowAction;
      switch (type) {
        case DATA_TABLE_ACTION_EDIT:
          dispatch(showModal({ type: MODAL_ACTION_EDIT, data }));
          break;
        case DATA_TABLE_ACTION_VIEW:
          dispatch(showModal({ type: MODAL_ACTION_VIEW, data }));
          break;
        case DATA_TABLE_ACTION_ROW_CLICK:
          if (userDetails.role === INTERNAL) {
            dispatch(showModal({ type: MODAL_ACTION_EDIT, data }));
          } else {
            dispatch(showModal({ type: MODAL_ACTION_VIEW, data }));
          }
          break;
        default:
          console.warn("Unknown action type:", type);
      }
      // Reset action after handling
      dispatch(triggerRowAction(null));
    }
  }, [rowAction, dispatch]);

  return (
    <Box className={generatePageClass(title)}>
      <PageTitle
        title={title}
        handleBtnNew={() => dispatch(showModal({ type: MODAL_ACTION_ADD }))}
        isShowNewBtn={isShowNewBtn}
      />
      <ContainerGrid>
        <Grid size={12}>
          <DataTable
            data={dataTable}
            totalRows={totalRows}
            columns={columns}
            exportFileName={title}
            actionButton={{
              clone: actionButton.clone,
              edit: actionButton.edit,
              view: actionButton.view,
            }}
          />
        </Grid>
      </ContainerGrid>
      <AddOrEditModal handleSubmit={handleSubmit} />
    </Box>
  );
};

export default CommonPageLayout;
