import { Typography, Switch, IconButton, Tooltip } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../store/slices";
import { accountApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS, USER_ROLES } from "../../utils/constants";
import { decodeJwt } from "../../utils/helpers";

const TOGGLE_SWITCH = "toogleSwitch";

// This component is for development purpose only. So that we can easily toggle between External and Internal user.
const UserRoleToggleSwitch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLocalhost = window.location.href.includes(USER_ROLES.LOCALHOST);

  // Retrieve the current user role from localStorage or default to internal user
  const getInitialUserRole = () => {
    const storedSwitchValue = localStorage.getItem(TOGGLE_SWITCH);
    if (storedSwitchValue === null) {
      localStorage.setItem(TOGGLE_SWITCH, "true");
      return true;
    }
    return storedSwitchValue === "true";
  };

  const [isInternalUser, setIsInternalUser] = useState(getInitialUserRole);

  useEffect(() => {
    isInternalUser ? fetchInternalUserData() : externalUserData();
  }, [isInternalUser]);

  // Handle toggle switch changes and reload the page
  const handleUserRoleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRole = event.target.checked.toString();
    localStorage.setItem(TOGGLE_SWITCH, newRole);
    localStorage.removeItem(USER_ROLES.USER_DETAILS);
    setIsInternalUser(event.target.checked);
    window.location.reload();
  };

  const fetchInternalUserData = async () => {
    try {
      const data = await accountApi.getAccountDetails();
      if (data) {
        const decodedData = decodeJwt(data.token);
        const userData = {
          first_name: decodedData.given_name,
          last_name: decodedData.family_name,
          email: decodedData.unique_name,
          role: USER_ROLES.INTERNAL,
          token: data.token,
        };
        dispatch(setUserDetails(userData));
        localStorage.setItem(USER_ROLES.USER_DETAILS, JSON.stringify(userData));
      }
    } catch (error) {
      console.error("Failed to fetch internal user details:", error);
    }
  };

  const externalUserData = () => {
    const storedUserData = localStorage.getItem(USER_ROLES.USER_DETAILS);
    if (storedUserData) {
      dispatch(setUserDetails(JSON.parse(storedUserData)));
    } else {
      navigate(`/${ROUTE_PATHS.AUTH}/${ROUTE_PATHS.LOGIN}`);
    }
  };

  return (
    <Box className="switchKeyWrapper" display="flex" alignItems="center">
      <Typography variant="body1">
        <Tooltip title="To use the External API for External users, first update the `defautlApiBaseUrl` in `utils/constants/userRoles.tsx` to the External API endpoint.">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        External User
      </Typography>
      <Switch
        checked={isInternalUser}
        onChange={handleUserRoleToggle}
        color="primary"
        inputProps={{ "aria-label": "controlled" }}
      />
      <Typography variant="body1">Internal User</Typography>
    </Box>
  );
};

export default UserRoleToggleSwitch;
