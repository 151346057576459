import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Actions supported in the Modal.
 */
export const MODAL_ACTION_ADD = "add";
export const MODAL_ACTION_EDIT = "edit";
export const MODAL_ACTION_VIEW = "view";

export interface ModalState {
  modalData?: any;
  isVisible: boolean;
  modalType: typeof MODAL_ACTION_ADD | typeof MODAL_ACTION_EDIT | typeof MODAL_ACTION_VIEW | null;
}

const initialState: ModalState = {
  isVisible: false,
  modalType: null,
  modalData: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (
      state,
      action: PayloadAction<{
        type: typeof MODAL_ACTION_ADD | typeof MODAL_ACTION_EDIT | typeof MODAL_ACTION_VIEW;
        data?: any;
      }>
    ) => {
      const { type, data } = action.payload;
      state.modalType = type;
      state.isVisible = true;
      state.modalData = data ?? null;
    },
    hideModal: (state) => {
      state.isVisible = false;
    },
    resetModalState: (state) => {
      state.modalType = null;
      state.modalData = null;
    },
  },
});

export const { showModal, hideModal, resetModalState } = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
