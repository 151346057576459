import { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { CommonTextField, ContainerGrid } from "../index";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideModal, resetModalState } from "../../store/slices";
import { LABELS } from "../../utils/constants";
import { savedFilterApi } from "../../api";
import { SavedFilterData } from "../../api/type";
import "./Modals.scss";
import { RootState } from "../../store";

interface Props {
  handleSubmit: (formData: any) => void;
}

const validationSchema = yup.object({
  filter_name: yup
    .string()
    .label(`${LABELS.FILTER} ${LABELS.NAME}`)
    .max(40, `${LABELS.FILTER} ${LABELS.NAME} must be at most 40 characters`)
    .test("filterOrExisting", `${LABELS.FILTER} ${LABELS.NAME} is required`, function (value) {
      return value || this.parent.existing_filter;
    }),
  existing_filter: yup
    .string()
    .label(`${LABELS.FILTER} ${LABELS.NAME}`)
    .test("existingOrFilter", `${LABELS.FILTER} ${LABELS.NAME} is required`, function (value) {
      return value || this.parent.filter_name;
    }),
});

const AddOrUpdateSavedFiltersModal = ({ handleSubmit }: Props) => {
  const dispatch = useAppDispatch();
  const { isVisible } = useAppSelector((state: RootState) => state.modal);
  const { userDetails } = useAppSelector((state: RootState) => state.user);
  const [savedFiltersDropdown, setSavedFiltersDropdown] = useState<SavedFilterData[]>([]);
  const [isSavedFiltersDropdownLoading, setIsSavedFiltersDropdownLoading] = useState<boolean>(false);
  const [hasFetchedSavedFilter, setHasFetchedSavedFilter] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { filter_name: "", existing_filter: "" },
    validationSchema: validationSchema,
    onSubmit: (formData) => {
      const payload = createPayload(formData);
      handleSubmit(payload);
      dispatch(hideModal());
      dispatch(resetModalState());
    },
  });

  const createPayload = (formData: typeof formik.values) => {
    return formData.filter_name && !formData.existing_filter
      ? { filter_name: formData.filter_name }
      : { id: formData.existing_filter };
  };

  const fetchSavedFilters = async () => {
    try {
      const { data } = await savedFilterApi.getSavedFilter(userDetails.email);
      setSavedFiltersDropdown(data.data);
      setHasFetchedSavedFilter(true);
      setIsSavedFiltersDropdownLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleModalExited = () => {
    formik.resetForm();
    setSavedFiltersDropdown([]);
    setHasFetchedSavedFilter(false);
    setIsSavedFiltersDropdownLoading(false);
    dispatch(resetModalState());
  };

  const loadSavedFiltersOnDropdownOpen = () => {
    if (!hasFetchedSavedFilter) {
      setIsSavedFiltersDropdownLoading(true);
      fetchSavedFilters();
    }
  };

  return (
    <Dialog
      closeAfterTransition={false}
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="sm"
      open={isVisible}
      scroll="body"
      onClose={() => dispatch(hideModal())}
      TransitionProps={{ onExited: handleModalExited }}
      className="cmnModalWrapper addOrUpdateSavedFiltersModalWrapper">
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <ContainerGrid disableGutters={true}>
            <Grid size={12}>
              <Box>
                <p className="heading" style={{ fontWeight: 500 }}>
                  Create a New Filter
                </p>
                <CommonTextField
                  name="filter_name"
                  label={`${LABELS.FILTER} ${LABELS.NAME}`}
                  formikProps={{ field: formik.getFieldProps("filter_name"), meta: formik.getFieldMeta("filter_name") }}
                />
              </Box>
            </Grid>
            <Grid size={12}>
              <Box>
                <Divider>OR</Divider>
              </Box>
            </Grid>
            <Grid size={12}>
              <Box>
                <p className="heading" style={{ fontWeight: 500 }}>
                  Update an Existing Filter
                </p>
                <Box className="commonInputWrap">
                  <FormControl
                    fullWidth
                    error={formik.touched.existing_filter && Boolean(formik.errors.existing_filter)}>
                    <InputLabel>{`${LABELS.SELECT_EXISTING_Filter}`}</InputLabel>
                    <Select
                      value={formik.values.existing_filter}
                      onOpen={loadSavedFiltersOnDropdownOpen}
                      onChange={(event) => formik.setFieldValue("existing_filter", event.target.value)}
                      input={<OutlinedInput label={`${LABELS.SELECT_DISTRIBUTORS}*`} />}>
                      {isSavedFiltersDropdownLoading ? (
                        <MenuItem disabled>
                          <em>Loading...</em>
                        </MenuItem>
                      ) : (
                        [
                          <MenuItem key="none" value="">
                            <em>None</em>
                          </MenuItem>,
                          ...savedFiltersDropdown.map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.filter_name}
                            </MenuItem>
                          )),
                        ]
                      )}
                    </Select>
                    {formik.touched.existing_filter && formik.errors.existing_filter && (
                      <FormHelperText>{formik.errors.existing_filter}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          </ContainerGrid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" className="cancelBtn" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button>
          <Button variant="contained" color="info" type="submit" className="submitBtn">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrUpdateSavedFiltersModal;
