import { useMemo, useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  MRT_RowData,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState,
} from "material-react-table";
import { Box, Button, ListItemIcon, MenuItem } from "@mui/material";
import { Edit, FileDownload, FileCopy, Visibility, FilterListOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { generateFileName } from "../../utils/helpers";
import {
  DATA_TABLE_ACTION_CLONE,
  DATA_TABLE_ACTION_EDIT,
  DATA_TABLE_ACTION_ROW_CLICK,
  DATA_TABLE_ACTION_VIEW,
  setFilters,
  triggerRowAction,
  updateColumnVisibilityState,
} from "../../store/slices";
import { LABELS } from "../../utils/constants";
import { OnChangeFn } from "@tanstack/table-core";
import { RootState } from "../../store";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import "./DataTable.scss";

interface CommonTableProps<T extends MRT_RowData> {
  data: T[];
  totalRows: number;
  columns: MRT_ColumnDef<T>[];
  exportFileName: string;
  actionButton?: {
    clone?: boolean;
    edit?: boolean;
    view?: boolean;
  };
}

const DataTable = <T extends MRT_RowData>({
  data,
  totalRows,
  columns,
  exportFileName,
  actionButton = {
    clone: false,
    edit: true,
    view: false,
  },
}: CommonTableProps<T>) => {
  const dispatch = useAppDispatch();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const {
    filters,
    isRefetching,
    columnVisibility: storedColumnVisibility,
  } = useAppSelector((state: RootState) => state.dataTable);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(filters.column);
  const [sorting, setSorting] = useState<MRT_SortingState>(filters.sorting);
  const [pagination, setPagination] = useState<MRT_PaginationState>(filters.pagination);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>({});
  const columnVisibilityRef = useRef(columnVisibility);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (columns && columns.length > 0) {
      const initialVisibility = columns.reduce((acc, column) => {
        const key = column.accessorKey || column.id;
        if (key) {
          acc[key] = true; // Default all columns to visible
        }
        return acc;
      }, {} as MRT_VisibilityState);

      setColumnVisibility(initialVisibility);
    }
  }, [columns]);

  // Sync columnVisibility with storedColumnVisibility when it changes
  useEffect(() => {
    if (columns && columns.length > 0 && storedColumnVisibility && Object.keys(storedColumnVisibility).length > 0) {
      setColumnVisibility((prev) => ({ ...prev, ...storedColumnVisibility }));
    }
  }, [storedColumnVisibility, columns]);

  useEffect(() => {
    if (filters.column.length > 0 || filters.sorting.length > 0) {
      setColumnFilters(filters.column);
      setSorting(filters.sorting);
      setPagination(filters.pagination);
    }
  }, [filters]);

  useEffect(() => {
    const payload = {
      column: columnFilters,
      pagination,
      sorting,
    };
    dispatch(setFilters(payload));
  }, [columnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  const handleExportToExcel = () => {
    const formatValue = (key: string, value: any) => {
      // Format date values if needed
      if (typeof value === "string" && value.includes("T") && dayjs(value).isValid()) {
        return dayjs(value).format("MM/DD/YYYY");
      }
      return value;
    };

    const mapRowToFormattedData = (row: any) => {
      const formattedRow = columns.reduce((acc, col) => {
        const key = col.accessorKey;
        const header = col.header;

        if (key) {
          acc[header] = formatValue(key, row[key as keyof typeof row]);
        }
        return acc;
      }, {} as Record<string, any>);

      if (exportFileName === "Request") {
        // Add custom columns for request page
        if (row.market_name) formattedRow[LABELS.MARKET_NAME] = row.market_name;
        if (row.application) formattedRow[LABELS.APPLICATION] = row.application;
        if (row.competitor_name) formattedRow[LABELS.COMPETITOR_NAME] = row.competitor_name;
        if (row.requested_by) formattedRow[LABELS.REQUESTED_BY] = row.requested_by;
        if (row.distributor_seller_first_name || row.distributor_seller_last_name) {
          formattedRow["Distributor Seller Name"] = `${row.distributor_seller_first_name ?? ""} ${
            row.distributor_seller_last_name ?? ""
          }`.trim();
        }
      }

      return formattedRow;
    };

    const formattedData = data.map(mapRowToFormattedData);
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const fileName = generateFileName(exportFileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  const handleColumnVisibilityChange: OnChangeFn<MRT_VisibilityState> = (updaterOrValue) => {
    const updatedVisibility = typeof updaterOrValue === "function" ? updaterOrValue(columnVisibility) : updaterOrValue;
    setColumnVisibility(updatedVisibility);
    dispatch(updateColumnVisibilityState(updatedVisibility));
  };

  useEffect(() => {
    columnVisibilityRef.current = columnVisibility;
  }, [columnVisibility]);

  const handleHideAllClick = () => {
    console.log("Hide all button clicked!", columnVisibilityRef.current);
    const updatedVisibility = Object.keys(columnVisibilityRef.current).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as MRT_VisibilityState);
    setColumnVisibility(updatedVisibility);
    dispatch(updateColumnVisibilityState(updatedVisibility));
  };

  const handleShowAllClick = () => {
    console.log("Show all button clicked!", columnVisibilityRef.current);
    const updatedVisibility = Object.keys(columnVisibilityRef.current).reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {} as MRT_VisibilityState);
    setColumnVisibility(updatedVisibility);
    dispatch(updateColumnVisibilityState(updatedVisibility));
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const buttons = Array.from(document.querySelectorAll<HTMLButtonElement>("button.MuiButtonBase-root"));
      buttons.forEach((button) => {
        if (button.textContent?.trim() === "Hide all") {
          button.removeEventListener("click", handleHideAllClick);
          button.addEventListener("click", handleHideAllClick);
        }
        if (button.textContent?.trim() === "Show all") {
          button.removeEventListener("click", handleShowAllClick);
          button.addEventListener("click", handleShowAllClick);
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  const table = useMaterialReactTable({
    columns: memoizedColumns,
    data,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    layoutMode: "grid",
    enableStickyHeader: true,
    enableFacetedValues: true,
    enableRowActions: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 80,
        grow: false,
      },
    },
    initialState: {
      density: "compact",
      showColumnFilters: true,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    positionToolbarAlertBanner: "bottom",
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnVisibilityChange: handleColumnVisibilityChange,
    rowCount: totalRows,
    state: {
      columnVisibility,
      columnFilters,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => dispatch(triggerRowAction({ type: DATA_TABLE_ACTION_ROW_CLICK, data: row.original })),
      sx: { cursor: "pointer" },
    }),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box>
          <Button onClick={handleExportToExcel} startIcon={<FileDownload />}>
            Export
          </Button>
        </Box>
        <Box>
          <Button onClick={() => columnFilters.length && setColumnFilters([])} startIcon={<FilterListOff />}>
            Clear All
          </Button>
        </Box>
      </Box>
    ),
    renderRowActionMenuItems: ({ closeMenu, row }) => {
      return [
        actionButton.clone && (
          <MenuItem
            key={0}
            onClick={() => {
              dispatch(triggerRowAction({ type: DATA_TABLE_ACTION_CLONE, data: row.original }));
            }}
            sx={{ m: 0, color: "#4cbcec" }}>
            <ListItemIcon>
              <FileCopy sx={{ color: "#4cbcec" }} />
            </ListItemIcon>
            Clone
          </MenuItem>
        ),
        actionButton.edit && (
          <MenuItem
            key={1}
            onClick={() => {
              dispatch(triggerRowAction({ type: DATA_TABLE_ACTION_EDIT, data: row.original }));
              closeMenu();
            }}
            sx={{ m: 0, color: "#2e7d32" }}>
            <ListItemIcon>
              <Edit sx={{ color: "#2e7d32" }} />
            </ListItemIcon>
            Edit
          </MenuItem>
        ),
        actionButton.view && (
          <MenuItem
            key={2}
            onClick={() => {
              dispatch(triggerRowAction({ type: DATA_TABLE_ACTION_VIEW, data: row.original }));
              closeMenu();
            }}
            sx={{ m: 0, color: "#1976d2" }}>
            <ListItemIcon>
              <Visibility sx={{ color: "#1976d2" }} />
            </ListItemIcon>
            View
          </MenuItem>
        ),
      ].filter(Boolean);
    },
    muiTablePaperProps: () => ({ className: "DataTableComponentContainer" }),
  });

  return <MaterialReactTable table={table} />;
};

export default DataTable;
