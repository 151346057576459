import { useEffect, useState } from "react";
import { Autocomplete, TextField, Box } from "@mui/material";
import { businessEntityApi, countryApi, distributorApi, stateApi } from "../../api";
import { CountryData, DistributorData, StateData, BusinessEntityData } from "../../api/type";

type CommonOptionType = {
  id: number;
  name?: string;
  distributor_name?: string;
  entity_name?: string;
  is_obsolete?: boolean;
  disabled?: boolean;
};

interface Props {
  label: string;
  value: string | number | null;
  onChange: (value: string | number | null) => void;
  apiType: "country" | "state" | "distributor" | "businessEntity";
  optionLabelKey: keyof CommonOptionType;
  error?: boolean;
  helperText?: string | boolean;
  disabled?: boolean;
}

const CommonAutocomplete = ({
  label,
  value,
  onChange,
  apiType,
  optionLabelKey,
  error,
  helperText,
  disabled = false,
}: Props) => {
  const [options, setOptions] = useState<CommonOptionType[]>([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    const payloadIsObsolete = { column: [{ id: "is_obsolete", value: "false" }] } as any;
    let response;
    switch (apiType) {
      case "country":
        response = await countryApi.getCountryForDropdown(payloadIsObsolete);
        setOptions(response.data.data.map((country: CountryData) => ({ id: country.id, name: country.name })));
        break;
      case "state":
        response = await stateApi.getStateForDropdown(payloadIsObsolete);
        setOptions(response.data.data.map((state: StateData) => ({ id: state.id, name: state.name })));
        break;
      case "distributor":
        response = await distributorApi.getDistributorForDropdown(payloadIsObsolete);
        setOptions(
          response.data.data.map((distributor: DistributorData) => ({
            id: distributor.id,
            distributor_name: distributor.distributor_name,
          }))
        );
        break;
      case "businessEntity":
        response = await businessEntityApi.getBusinessEntityForDropdown(payloadIsObsolete);
        setOptions(
          response.data.data.map((state: BusinessEntityData) => ({ id: state.id, entity_name: state.entity_name }))
        );
        break;
    }
  };

  return (
    <Autocomplete
      disabled={disabled}
      value={options.find((opt) => opt.id === value) || null}
      onChange={(e, newValue) => onChange(newValue ? newValue.id : null)}
      options={options}
      autoHighlight
      getOptionLabel={(option) => (option[optionLabelKey] as string) || ""}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props as any;
        return (
          <Box key={option.id} component="li" {...optionProps}>
            {option[optionLabelKey] as string}
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{ ...params.inputProps, autoComplete: "new-password" }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

export default CommonAutocomplete;
