import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./reduxHooks";
import { finishResetting, resetDataTableToInitialState } from "../store/slices";

const useNavigateWithReset = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const navigateWithReset = (path: string) => {
    dispatch(resetDataTableToInitialState());
    setTimeout(() => {
      dispatch(finishResetting());
      navigate(path);
    }, 200);
  };

  return navigateWithReset;
};

export default useNavigateWithReset;
