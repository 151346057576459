import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { ContainerGrid } from "../index";
import "./Footer.scss";

const Footer = () => {
  return (
    <Box className="footerComponentContainer">
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 8 }}>
          <Box className="confidentialTextWrapper">
            <p className="txt">confidential</p>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }} sx={{ textAlign: "right" }}>
          <Box className="privacyTextWrapper">
            <a href="https://www.covestro.com/en/legal/privacy-statement" target="_blank" className="link">
              Privacy Statement | Covestro
            </a>
          </Box>
        </Grid>
      </ContainerGrid>
    </Box>
  );
};

export default Footer;
