import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
  MRT_VisibilityState,
} from "material-react-table";

/**
 * Actions supported in the DataTable.
 */
export const DATA_TABLE_ACTION_CLONE = "clone"; // Used for cloning rows
export const DATA_TABLE_ACTION_EDIT = "edit"; // Used for editing rows
export const DATA_TABLE_ACTION_VIEW = "view"; // Used for viewing rows
export const DATA_TABLE_ACTION_ROW_CLICK = "row_click"; // Triggered when a row is clicked

type DataTableActionType =
  | typeof DATA_TABLE_ACTION_CLONE
  | typeof DATA_TABLE_ACTION_EDIT
  | typeof DATA_TABLE_ACTION_VIEW
  | typeof DATA_TABLE_ACTION_ROW_CLICK;

interface RowActionPayload {
  type: DataTableActionType;
  data: any;
}

export interface Filter {
  column: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
  user_type?: string;
  email?: string;
}

export interface DataTableState {
  isError: boolean;
  isRefetching: boolean;
  isResetting: boolean;
  filters: Filter;
  columnVisibility?: MRT_VisibilityState;
  rowAction?: any;
}

const initialState: DataTableState = {
  isError: false,
  isRefetching: false,
  isResetting: false,
  filters: {
    column: [],
    sorting: [],
    pagination: {
      pageIndex: 0,
      pageSize: 10,
    },
  },
  columnVisibility: {},
  rowAction: null as RowActionPayload | null,
};

const dataTableSlice = createSlice({
  name: "dataTable",
  initialState,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<{
        column?: MRT_ColumnFiltersState;
        sorting?: MRT_SortingState;
        pagination?: MRT_PaginationState;
      }>
    ) => {
      const { column, sorting, pagination } = action.payload;
      if (column !== undefined) state.filters.column = column;
      if (sorting !== undefined) state.filters.sorting = sorting;
      if (pagination !== undefined) state.filters.pagination = pagination;
    },
    updateColumnVisibilityState: (state, action: PayloadAction<MRT_VisibilityState>) => {
      state.columnVisibility = action.payload;
    },
    showLoader: (state) => {
      state.isRefetching = true;
    },
    hideLoader: (state) => {
      state.isRefetching = false;
    },
    resetDataTableToInitialState: (state) => {
      state.isResetting = true;
      state.filters = initialState.filters;
      state.columnVisibility = initialState.columnVisibility;
    },
    finishResetting: (state) => {
      state.isResetting = false;
    },
    triggerRowAction: (state, action: PayloadAction<RowActionPayload | null>) => {
      state.rowAction = action.payload;
    },
  },
});

export const {
  setFilters,
  updateColumnVisibilityState,
  showLoader,
  hideLoader,
  resetDataTableToInitialState,
  finishResetting,
  triggerRowAction,
} = dataTableSlice.actions;

export const dataTableReducer = dataTableSlice.reducer;
