import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { FieldProps } from "formik";

interface Props {
  name: string;
  label: string;
  formikProps: {
    field: FieldProps<any>["field"];
    meta: FieldProps<any>["meta"];
  };
  disabled?: boolean;
}

const CommonCheckboxField = ({ name, label, formikProps, disabled = false }: Props) => {
  const { field, meta } = formikProps;
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            id={`form-${name}`}
            name={name}
            checked={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={disabled}
          />
        }
        label={label}
      />
      <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
    </>
  );
};

export default CommonCheckboxField;
