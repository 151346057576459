import { createTitle } from "../helpers";
import { ROUTE_PATHS } from "./routePath";
import { EXTERNAL, INTERNAL } from "./userRoles";

const ROLES = [INTERNAL, EXTERNAL];

export const NAV_LINKS = [
  {
    title: createTitle(ROUTE_PATHS.MY_ACCOUNT),
    path: `/${ROUTE_PATHS.MY_ACCOUNT}`,
    role: ROLES,
  },
  {
    title: createTitle(ROUTE_PATHS.REQUESTS),
    path: `/${ROUTE_PATHS.REQUESTS}`,
    role: ROLES,
  },
  {
    title: createTitle(ROUTE_PATHS.APPLICATION_MANAGEMENT),
    path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}`,
    role: ROLES,
    subLinks: [
      {
        title: createTitle(ROUTE_PATHS.BUSINESS_ENTITY),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.BUSINESS_ENTITY}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.COMPETITOR),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.COMPETITOR}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.COUNTRY),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.COUNTRY}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.COVESTRO_SELLER),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.COVESTRO_SELLER}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR_CONTACT),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR_CONTACT}`,
        role: ROLES,
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR_CUSTOMER),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR_CUSTOMER}`,
        role: ROLES,
      },
      {
        title: createTitle(ROUTE_PATHS.DISTRIBUTOR_LOCATION),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.DISTRIBUTOR_LOCATION}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.MARKET),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.MARKET}`,
        role: [ROLES[0]],
      },
      {
        title: "OEM",
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.OEM}`,
        role: [ROLES[0]],
      },
      {
        title: createTitle(ROUTE_PATHS.STATE),
        path: `/${ROUTE_PATHS.APPLICATION_MANAGEMENT}/${ROUTE_PATHS.STATE}`,
        role: [ROLES[0]],
      },
    ],
  },
];
