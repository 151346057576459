import { API_PATHS as API } from "../../utils/constants";
import { axios } from "../axiosInstance";
import { FieldStatusData, UpdateFieldStatusPayload, CreateFieldStatusPayload, ApiResponse } from "./types";

const fieldStatusApi = {
  getFieldStatus(user_email: string) {
    const params = { user_email };
    return axios.get<ApiResponse<FieldStatusData>>(API.FIELD_STATUS, {
      params,
      headers: { noLoader: "true" },
    });
  },
  updateFieldStatus(payload: UpdateFieldStatusPayload) {
    const { id, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<FieldStatusData>>(`${API.FIELD_STATUS}/${id}`, payloadWithoutId, {
      headers: { noLoader: "true" },
    });
  },
  createFieldStatus(payload: CreateFieldStatusPayload) {
    return axios.post<ApiResponse<FieldStatusData>>(API.FIELD_STATUS, payload, {
      headers: { noLoader: "true" },
    });
  },
};

export default fieldStatusApi;
