import { MouseEvent } from "react";
import { Box, Button, Menu } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { useLocation } from "react-router-dom";
import { NAV_LINKS } from "../../../utils/constants";
import { useNavigateWithReset } from "../../../hooks";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface DesktopMenuProps {
  currentRole: string;
  isDropdownMenuOpen: boolean;
  dropdownMenuAnchorEl: null | HTMLElement;
  openDropdownMenu: (event: MouseEvent<HTMLButtonElement>) => void;
  closeDropdownMenu: () => void;
}

const DesktopMenu = ({
  currentRole,
  isDropdownMenuOpen,
  dropdownMenuAnchorEl,
  openDropdownMenu,
  closeDropdownMenu,
}: DesktopMenuProps) => {
  const location = useLocation();
  const navigateWithReset = useNavigateWithReset();

  return (
    <Box sx={{ paddingLeft: 3, flexGrow: 1, display: { xs: "none", sm: "none", md: "block" } }}>
      {NAV_LINKS.map((navItem) => (
        <Fragment key={navItem.title}>
          {!navItem.subLinks && navItem.role.includes(currentRole) && (
            <Button
              onClick={() => navigateWithReset(navItem.path)}
              sx={{ color: "#ffffff", textTransform: "none", "&.active": { color: "#4cbcec" } }}>
              {navItem.title}
            </Button>
          )}

          {navItem.subLinks && (
            <>
              <Button
                className={location.pathname.includes("application-management") ? "active" : ""}
                aria-controls={isDropdownMenuOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isDropdownMenuOpen ? "true" : undefined}
                onClick={openDropdownMenu}
                endIcon={<ArrowDropDownIcon />}
                sx={{ color: "#ffffff", textTransform: "none", "&.active": { color: "#4cbcec" } }}>
                {navItem.title}
              </Button>

              <Menu
                anchorEl={dropdownMenuAnchorEl}
                open={isDropdownMenuOpen}
                onClose={closeDropdownMenu}
                MenuListProps={{ "aria-labelledby": "basic-button" }}
                sx={{ "& .MuiMenu-paper": { backgroundColor: "#262626" } }}>
                {navItem.subLinks.map((subNavItem) =>
                  subNavItem.role.includes(currentRole) ? (
                    <Button
                      key={subNavItem.title}
                      onClick={() => {
                        closeDropdownMenu();
                        navigateWithReset(subNavItem.path);
                      }}
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        display: "block",
                        textTransform: "none",
                        color: "#ffffff",
                        "&.active": { color: "#4cbcec" },
                      }}>
                      {subNavItem.title}
                    </Button>
                  ) : null
                )}
              </Menu>
            </>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default DesktopMenu;
