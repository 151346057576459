import { useState, useEffect } from "react";
import { AddOrEditBusinessEntityModal, CommonPageLayout } from "../../../components";
import { businessEntityApi } from "../../../api";
import { BusinessEntityData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS } from "../../../utils/constants";
import { setFilters } from "../../../store/slices";
import { RootState } from "../../../store";

const columns: MRT_ColumnDef<BusinessEntityData>[] = [
  { accessorKey: "entity_name", header: `${LABELS.BUSINESS_ENTITY} ${LABELS.NAME}` },
  createBooleanColumn<BusinessEntityData>("is_ep", LABELS.OEM_LAYOUT),
  createBooleanColumn<BusinessEntityData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const BusinessEntity = () => {
  const dispatch = useAppDispatch();

  const { filters, isResetting } = useAppSelector((state: RootState) => state.dataTable);

  const [dataTable, setDataTable] = useState<BusinessEntityData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(setFilters({ column: initialColumnFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting) {
      fetchData();
    }
  }, [filters, hasInitialFiltersBeenApplied]);

  const fetchData = async () => {
    try {
      const { data } = await businessEntityApi.getBusinessEntity(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: BusinessEntityData) => {
    try {
      if (formData.id) await businessEntityApi.updateBusinessEntity(formData);
      else await businessEntityApi.createBusinessEntity(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Business Entity"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditBusinessEntityModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default BusinessEntity;
