import { Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import MarketData, { ApiResponse } from "./types";

const marketApi = {
  getMarket(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<MarketData>>(API.MARKET, { params, headers: { noLoader: "true" } });
  },
  updateMarket(payload: MarketData) {
    const { id, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<MarketData>>(`${API.MARKET}/${id}`, payloadWithoutId);
  },
  createMarket(payload: MarketData) {
    return axios.post<ApiResponse<MarketData>>(API.MARKET, payload);
  },
  getMarketForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<MarketData>>(API.MARKET, { params });
  },
};

export default marketApi;
