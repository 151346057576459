import { ReactNode } from "react";
import { Container, SxProps } from "@mui/material";
import Grid from "@mui/material/Grid2";

interface Props {
  children?: ReactNode;
  spacing?: number;
  disableGutters?: boolean;
  sx?: SxProps;
}

const ContainerGrid = ({ children, spacing = 3, disableGutters = false, sx = {} }: Props) => {
  return (
    <Container maxWidth={false} disableGutters={disableGutters}>
      <Grid container spacing={spacing} sx={{ ...sx }}>
        {children}
      </Grid>
    </Container>
  );
};

export default ContainerGrid;
