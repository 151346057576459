import { useState, useEffect } from "react";
import { AddOrEditDistributorModal, CommonPageLayout } from "../../../components";
import { distributorApi } from "../../../api";
import { DistributorData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS } from "../../../utils/constants";
import { setFilters } from "../../../store/slices";
import { RootState } from "../../../store";

const columns: MRT_ColumnDef<DistributorData>[] = [
  { accessorKey: "distributor_name", header: `${LABELS.DISTRIBUTOR} ${LABELS.NAME}` },
  { accessorKey: "sales_orgnisation", header: LABELS.SALES_ORGANISATION },
  { accessorKey: "sold_to_number", header: LABELS.SOLD_NUMBER },
  { accessorKey: "entity_name", header: LABELS.BUSINESS_ENTITY },
  createBooleanColumn<DistributorData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const Distributor = () => {
  const dispatch = useAppDispatch();

  const { filters, isResetting } = useAppSelector((state: RootState) => state.dataTable);

  const [dataTable, setDataTable] = useState<DistributorData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(setFilters({ column: initialColumnFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting) {
      fetchData();
    }
  }, [filters, hasInitialFiltersBeenApplied]);

  const fetchData = async () => {
    try {
      const { data } = await distributorApi.getDistributor(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: DistributorData) => {
    try {
      if (formData.id) await distributorApi.updateDistributor(formData);
      else await distributorApi.createDistributor(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Distributor"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditDistributorModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default Distributor;
