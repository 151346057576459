import { Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import DistributorCustomerData, { ApiResponse } from "./types";

const distributorCustomerApi = {
  getDistributorCustomer(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<DistributorCustomerData>>(API.DISTRIBUTOR_CUSTOMER, {
      params,
      headers: { noLoader: "true" },
    });
  },
  updateDistributorCustomer(payload: DistributorCustomerData) {
    const { id, country_name, distributor_name, state_name, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<DistributorCustomerData>>(`${API.DISTRIBUTOR_CUSTOMER}/${id}`, payloadWithoutId);
  },
  createDistributorCustomer(payload: DistributorCustomerData) {
    return axios.post<ApiResponse<DistributorCustomerData>>(API.DISTRIBUTOR_CUSTOMER, payload);
  },
  getDistributorCustomerForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<DistributorCustomerData>>(API.DISTRIBUTOR_CUSTOMER, { params });
  },
};

export default distributorCustomerApi;
