import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContainerGrid, Loader } from "../../../components";

declare global {
  interface Window {
    gigya: any;
  }
}

const ExternalUser = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleGigyaReady = () => {
      window.gigya.accounts.showScreenSet({
        screenSet: "COV_CUPS-ProfileUpdate",
        startScreen: "cups-update-profile-screen",
        containerID: "profileScreenGigyaContainer",
        onBeforeScreenLoad: () => {
          setIsLoading(false);
        },
      });
    };
    handleGigyaReady();
  }, [navigate]);

  return (
    <>
      {isLoading && <Loader />}
      <Box className="myAccountPageContainer external">
        <ContainerGrid>
          <Grid size={12}>
            <Box id="profileScreenGigyaContainer" />
          </Grid>
        </ContainerGrid>
      </Box>
    </>
  );
};

export default ExternalUser;
