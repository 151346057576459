import { Filter } from "../../store/slices";
import { API_PATHS as API } from "../../utils/constants";
import { generateParams, generateParamsForDropdown } from "../../utils/helpers";
import { axios } from "../axiosInstance";
import BusinessEntityData, { ApiResponse } from "./types";

const businessEntityApi = {
  getBusinessEntity(filters: Filter) {
    const params = generateParams(filters);
    return axios.get<ApiResponse<BusinessEntityData>>(API.BUSINESS_ENTITY, { params, headers: { noLoader: "true" } });
  },
  updateBusinessEntity(payload: BusinessEntityData) {
    const { id, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<BusinessEntityData>>(`${API.BUSINESS_ENTITY}/${id}`, payloadWithoutId);
  },
  createBusinessEntity(payload: BusinessEntityData) {
    return axios.post<ApiResponse<BusinessEntityData>>(API.BUSINESS_ENTITY, payload);
  },
  getBusinessEntityForDropdown(filters: Filter) {
    const params = generateParamsForDropdown(filters);
    return axios.get<ApiResponse<BusinessEntityData>>(API.BUSINESS_ENTITY, { params });
  },
};

export default businessEntityApi;
