import { useState, useEffect } from "react";
import { AddOrEditMarketModal, CommonPageLayout } from "../../../components";
import { marketApi } from "../../../api";
import { MarketData } from "../../../api/type";
import { MRT_ColumnDef } from "material-react-table";
import { createBooleanColumn } from "../../../utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { LABELS } from "../../../utils/constants";
import { setFilters } from "../../../store/slices";
import { RootState } from "../../../store";

const columns: MRT_ColumnDef<MarketData>[] = [
  { accessorKey: "market_name", header: LABELS.MARKET_NAME },
  createBooleanColumn<MarketData>("is_obsolete", LABELS.OBSOLETE),
];

const initialColumnFilter = [{ id: "is_obsolete", value: "false" }];

const Market = () => {
  const dispatch = useAppDispatch();

  const { filters, isResetting } = useAppSelector((state: RootState) => state.dataTable);

  const [dataTable, setDataTable] = useState<MarketData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);

  useEffect(() => {
    dispatch(setFilters({ column: initialColumnFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting) {
      fetchData();
    }
  }, [filters, hasInitialFiltersBeenApplied]);

  const fetchData = async () => {
    try {
      const { data } = await marketApi.getMarket(filters);
      setTotalRows(data.total);
      setDataTable(data.data);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const handleSubmit = async (formData: MarketData) => {
    try {
      if (formData.id) await marketApi.updateMarket(formData);
      else await marketApi.createMarket(formData);
      fetchData();
    } catch (err) {
      console.error("Failed to submit data:", err);
    }
  };

  return (
    <CommonPageLayout
      title="Market"
      dataTable={dataTable}
      totalRows={totalRows}
      columns={columns}
      AddOrEditModal={AddOrEditMarketModal}
      handleSubmit={handleSubmit}
    />
  );
};

export default Market;
