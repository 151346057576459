import Grid from "@mui/material/Grid2";
import { Button, Box } from "@mui/material";
import { ContainerGrid } from "../index";
import "./PageTitle.scss";

interface Props {
  title: string;
  handleBtnNew?: (x: any) => void;
  isShowNewBtn?: boolean;
}

const PageTitle = ({ title, handleBtnNew, isShowNewBtn = true }: Props) => {
  return (
    <Box className="pageTitleComponentContainer">
      <ContainerGrid>
        <Grid size={12}>
          <Box className="headingWrapper">
            <h1 className="heading">{title}</h1>
            {isShowNewBtn && (
              <Button variant="contained" className="cmnFillBtn newBtn widthAuto" onClick={handleBtnNew}>
                New
              </Button>
            )}
          </Box>
        </Grid>
      </ContainerGrid>
    </Box>
  );
};

export default PageTitle;
