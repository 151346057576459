import { Box, TextField } from "@mui/material";
import { FieldProps } from "formik";

interface Props {
  name: string;
  label: string;
  formikProps: {
    field: FieldProps<any>["field"];
    meta: FieldProps<any>["meta"];
  };
  type?: "text" | "email";
  variant?: "standard" | "outlined" | "filled";
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
}

const CommonTextField = ({
  name,
  label,
  formikProps,
  type = "text",
  variant = "standard",
  disabled = false,
  multiline = false,
  rows,
}: Props) => {
  const { field, meta } = formikProps;

  return (
    <Box className="commonInputWrap">
      <TextField
        fullWidth
        variant={variant}
        id={`form-${name}`}
        label={label}
        type={type}
        autoComplete="off"
        className="commonInputFormControl"
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        disabled={disabled}
        multiline={multiline}
        rows={multiline ? rows : undefined}
      />
    </Box>
  );
};

export default CommonTextField;
