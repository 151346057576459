import { useLocation, useParams } from "react-router-dom";
import EditOrCloneRequest from "./EditOrCloneRequest";
import ViewRequest from "./ViewRequest";
import CreateRequest from "./CreateRequest";
import { LABELS } from "../../../utils/constants";

const PriceSupportRequest = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const { id } = useParams();

  if (id && (type === LABELS.EDIT || type === LABELS.CLONE)) {
    return <EditOrCloneRequest />;
  }

  if (id && type === LABELS.VIEW) {
    return <ViewRequest />;
  }

  return <CreateRequest />;
};

export default PriceSupportRequest;
