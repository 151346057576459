export const ROUTE_PATHS = {
  AUTH: "auth",
  LOGIN: "login",
  RESET_PASSWORD: "reset-password",
  SET_NEW_PASSWORD: "set-new-password",

  HOME: "home",
  MY_ACCOUNT: "my-account",
  SALES_REPORT: "sales-report",
  SWITCH_DISTRIBUTOR: "switch-distributor",

  REQUESTS: "requests",
  PRICE_SUPPORT_REQUEST: "price-support-request",

  APPLICATION_MANAGEMENT: "application-management",
  ACCOUNTS: "accounts",
  BUSINESS_ENTITY: "business-entity",
  COMMENTS: "comments",
  COMPETITOR: "competitor",
  COUNTRY: "country",
  COVESTRO_SELLER: "covestro-seller",
  DISTRIBUTOR: "distributor",
  DISTRIBUTOR_CONTACT: "distributor-contact",
  DISTRIBUTOR_CUSTOMER: "distributor-customer",
  DISTRIBUTOR_LOCATION: "distributor-location",
  MARKET: "market",
  OEM: "oem",
  PRODUCT_INFORMATION: "product-information",
  SPECIAL_PRICING: "special-pricing",
  STATE: "state",
};
