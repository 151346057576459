import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

interface MobileMenuProps {
  toggleMobileMenu: () => void;
}

const buttonStyles = {
  mr: 2,
  display: { xs: "block", sm: "block", md: "none" },
};

const MobileMenu = ({ toggleMobileMenu }: MobileMenuProps) => (
  <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={toggleMobileMenu} sx={buttonStyles}>
    <MenuIcon />
  </IconButton>
);

export default MobileMenu;
