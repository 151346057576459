import { API_PATHS as API } from "../../utils/constants";
import { axios } from "../axiosInstance";
import ProductInformationData, { ApiResponse } from "./types";

const productInformationApi = {
  getProductInformation(id: number) {
    return axios.get<ApiResponse<ProductInformationData>>(`${API.PRODUCT_INFORMATION}/${id}`);
  },
};

export default productInformationApi;
