import { Navigate, Route, Routes } from "react-router-dom";
import {
  ApplicationManagement,
  BusinessEntity,
  Competitor,
  Country,
  CovestroSeller,
  Distributor,
  DistributorContact,
  DistributorCustomer,
  DistributorLocation,
  Home,
  Market,
  OEM,
  MyAccount,
  Requests,
  ResetPassword,
  Login,
  State,
  PriceSupportRequest,
  SetNewPassword,
  Auth,
} from "../pages";
import { ROUTE_PATHS as Path, USER_ROLES } from "../utils/constants";
import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const AppRoutes = () => {
  const { userDetails } = useAppSelector((state: RootState) => state.user);
  const isInternalRole = userDetails.role === USER_ROLES.INTERNAL;

  return (
    <>
      <Routes>
        <Route path={`${Path.AUTH}`} element={<Auth />}>
          <Route path={`${Path.LOGIN}`} element={<Login />} />
          <Route path={`${Path.RESET_PASSWORD}`} element={<ResetPassword />} />
          <Route path={`${Path.SET_NEW_PASSWORD}`} element={<SetNewPassword />} />
        </Route>

        <Route path="/" element={<Home />} />
        <Route path={`${Path.MY_ACCOUNT}`} element={<MyAccount />} />

        <Route path={`${Path.REQUESTS}`} element={<Requests />} />
        <Route path={`${Path.REQUESTS}/${Path.PRICE_SUPPORT_REQUEST}/:id?`} element={<PriceSupportRequest />} />

        <Route path={`${Path.APPLICATION_MANAGEMENT}`} element={<ApplicationManagement />}>
          <Route path={`${Path.DISTRIBUTOR_CONTACT}`} element={<DistributorContact />} />
          <Route path={`${Path.DISTRIBUTOR_CUSTOMER}`} element={<DistributorCustomer />} />
          {isInternalRole ? (
            <>
              <Route path={`${Path.BUSINESS_ENTITY}`} element={<BusinessEntity />} />
              <Route path={`${Path.COMPETITOR}`} element={<Competitor />} />
              <Route path={`${Path.COUNTRY}`} element={<Country />} />
              <Route path={`${Path.COVESTRO_SELLER}`} element={<CovestroSeller />} />
              <Route path={`${Path.DISTRIBUTOR}`} element={<Distributor />} />
              <Route path={`${Path.DISTRIBUTOR_LOCATION}`} element={<DistributorLocation />} />
              <Route path={`${Path.MARKET}`} element={<Market />} />
              <Route path={`${Path.OEM}`} element={<OEM />} />
              <Route path={`${Path.STATE}`} element={<State />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
