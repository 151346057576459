import { ReactComponent as UserIcon } from "./userIcon.svg";
import HeaderLogo from "./headerLogo.png";
import BackgroundWaves from "./backgroundWaves.png";

const Images = {
  HeaderLogo,
  BackgroundWaves,
  UserIcon,
};

export default Images;
