import { Box } from "@mui/material";
import { PageTitle } from "../../components";
import "./Home.scss";

const Home = () => {
  return (
    <Box className="homePageContainer">
      <PageTitle title="Welcome to the Covestro Credit Upon Proof of Sales app" isShowNewBtn={false} />
    </Box>
  );
};

export default Home;
