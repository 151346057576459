import { Alert, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideAlert } from "../../store/slices";
import { RootState } from "../../store";

const AlertSnackbar = () => {
  const { isVisible, message, alertType, duration } = useAppSelector((state: RootState) => state.alertSnackbar);
  const dispatch = useAppDispatch();

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isVisible}
        autoHideDuration={duration}
        onClose={() => dispatch(hideAlert())}>
        <Alert onClose={() => dispatch(hideAlert())} severity={alertType} variant="filled" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertSnackbar;
