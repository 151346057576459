import Grid from "@mui/material/Grid2";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ContainerGrid } from "../index";
import "./Modals.scss";

interface Props {
  open: boolean;
  onClose: (value: string) => void;
}

const ConfirmationModal = ({ open, onClose }: Props) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      scroll="body"
      onClose={() => onClose("cancel")}
      className="cmnModalWrapper deleteModalWrapper">
      <DialogContent>
        <ContainerGrid disableGutters={true}>
          <Grid size={12}>
            <Box>
              <h2>Are you sure you want to cancel?</h2>
              <p>You have unsaved changes in this form. If you cancel, any changes you made will not be saved.</p>
            </Box>
          </Grid>
        </ContainerGrid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          className="cmnBorderBtn yellow cancelBtn widthAuto"
          onClick={() => onClose("yes")}>
          Yes, Cancel
        </Button>
        <Button
          variant="contained"
          color="info"
          className="cmnFillBtn proceedBtn widthAuto"
          onClick={() => onClose("no")}>
          No, Return
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
