import { Box } from "@mui/material";
import "./Loader.scss";

const Loader = () => {
  return (
    <Box className="loaderComponentContainer">
      <span className="loader"></span>
    </Box>
  );
};

export default Loader;
