import { USER_ROLES } from "../../utils/constants";

const accountApi = {
  getAccountDetails: async () => {
    const apiUrl = USER_ROLES.getSsoUrlByCurrentDomain() as string;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  },
};

export default accountApi;
