import { API_PATHS as API } from "../../utils/constants";
import { axios } from "../axiosInstance";
import { SavedFilterData, UpdateSavedFilterPayload, CreateSavedFilterPayload, ApiResponse } from "./types";

const savedFilterApi = {
  getSavedFilter(user_email: string) {
    const params = { user_email };
    return axios.get<ApiResponse<SavedFilterData>>(API.SAVED_FILTER, {
      params,
      headers: { noLoader: "true" },
    });
  },
  updateSavedFilter(payload: UpdateSavedFilterPayload) {
    const { id, ...payloadWithoutId } = payload;
    return axios.put<ApiResponse<SavedFilterData>>(`${API.SAVED_FILTER}/${id}`, payloadWithoutId);
  },
  createSavedFilter(payload: CreateSavedFilterPayload) {
    return axios.post<ApiResponse<SavedFilterData>>(API.SAVED_FILTER, payload);
  },
};

export default savedFilterApi;
