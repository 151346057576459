import Grid from "@mui/material/Grid2";
import { useState, useEffect, useRef } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { LABELS, ROUTE_PATHS } from "../../../utils/constants";
import { DataTable, PageTitle, ContainerGrid, Loader } from "../../../components";
import { FieldStatusData, RequestData } from "../../../api/type";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestApi, fieldStatusApi, distributorContactApi } from "../../../api";
import {
  DATA_TABLE_ACTION_CLONE,
  DATA_TABLE_ACTION_ROW_CLICK,
  DATA_TABLE_ACTION_VIEW,
  setFilters,
  triggerRowAction,
  updateColumnVisibilityState,
} from "../../../store/slices";
import {
  compareFieldVisibilityStates,
  filterFalseValues,
  getCurrentDateISOEndOfDayTime,
  getCurrentDateISOStartOfDayTime,
  getFullName,
  getNext60DaysISO,
  getPrevious60DaysISO,
} from "../../../utils/helpers";
import { DEFAULT_COLUMNS, ONLY_DATE_COLUMNS, initialSortingFilter } from "../contant";
import { RootState } from "../../../store";

interface Categories {
  id: string;
  name: string;
}

const categories: Categories[] = [
  { id: "1", name: "Submitted" },
  { id: "2", name: "Approved" },
  { id: "3", name: "Expiration in next 60 days" },
  { id: "4", name: "Expired in last 60 days" },
];

const ExternalUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isInitialRender = useRef(true);

  const { filters, columnVisibility, isResetting, rowAction } = useAppSelector((state: RootState) => state.dataTable);
  const { userDetails } = useAppSelector((state: RootState) => state.user);

  const [dataTable, setDataTable] = useState<RequestData[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [activeCategory, setActiveCategory] = useState<string>(categories[0].id);
  const [columns, setColumns] = useState<MRT_ColumnDef<RequestData>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasInitialFiltersBeenApplied, setHasInitialFiltersBeenApplied] = useState<Boolean>(false);
  const [fieldStatus, setFieldStatus] = useState<FieldStatusData>();
  const [isFieldStatusLoaded, setIsFieldStatusLoaded] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();

  const navigateToRequestDetail = (id: string, actionType: string) => {
    navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}/${id}?type=${actionType}`);
  };

  const saveFieldStatusData = async (fields: Record<string, boolean>) => {
    const payload = { field: fields, user_email: userDetails.email };
    try {
      if (fieldStatus?.id) await fieldStatusApi.updateFieldStatus({ ...payload, id: fieldStatus?.id });
      else await fieldStatusApi.createFieldStatus(payload);
      fetchSavedFields(false);
    } catch (error) {
      console.error("Failed to save field status:", error);
    }
  };

  const fetchSavedFields = async (shouldDispatch = true) => {
    try {
      const response = await fieldStatusApi.getFieldStatus(userDetails.email);
      const savedData = response.data.data;
      if (savedData.length) {
        const savedFieldStatus = savedData[0];
        setFieldStatus(savedFieldStatus);
        shouldDispatch && dispatch(updateColumnVisibilityState(savedFieldStatus.field));
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setIsFieldStatusLoaded(true);
    }
  };

  const fetchRequestData = async () => {
    try {
      const categoryFilters = getCategoryFilters();
      const filterToAdd = categoryFilters[activeCategory as keyof typeof categoryFilters];
      const updatedFilters = getUpdatedFilters(filterToAdd);
      updateColumnsBasedOnCategory();
      const { data } = await requestApi.getRequest(updatedFilters);
      setTotalRows(data.total);
      setDataTable(data.data);
      isInitialRender.current = false;
    } catch (err) {
      console.error("Failed to fetch data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserId = async () => {
    try {
      const filterPayload = [
        { id: "is_obsolete", value: "false" },
        { id: "email", value: userDetails.email },
      ];
      const { data } = await distributorContactApi.getDistributorByEmail(filterPayload);
      setUserId(data.data[0].id);
    } catch (err) {
      console.error("Failed to fetch data:", err);
    }
  };

  const getCategoryFilters = () => ({
    "1": { id: "status", value: [LABELS.SUBMITTED] },
    "2": { id: "status", value: [LABELS.APPROVED] },
    "3": [
      { id: "valid_to_range", value: { start: getCurrentDateISOStartOfDayTime(), end: getNext60DaysISO() } },
      { id: "status", value: [LABELS.SUBMITTED, LABELS.APPROVED] },
    ],
    "4": [
      { id: "valid_to_range", value: { start: getPrevious60DaysISO(), end: getCurrentDateISOEndOfDayTime() } },
      { id: "status", value: [LABELS.SUBMITTED, LABELS.APPROVED] },
    ],
  });

  const getUpdatedFilters = (filterToAdd: any) => {
    if (!filterToAdd) return filters;

    const userFilter = [
      { id: "requested_by", value: getFullName(userDetails) },
      { id: "requested_by_id", value: userId },
    ];

    return {
      ...filters,
      column: Array.isArray(filterToAdd)
        ? [...filters.column, ...userFilter, ...filterToAdd]
        : [...filters.column, ...userFilter, filterToAdd],
      user_type: "external",
    };
  };

  const updateColumnsBasedOnCategory = () => {
    if (["3", "4"].includes(activeCategory)) {
      setColumns(DEFAULT_COLUMNS);
    } else {
      const filteredColumns = DEFAULT_COLUMNS.filter(
        (col) => col.accessorKey !== "valid_from" && col.accessorKey !== "valid_to"
      );
      const validFromIndex = DEFAULT_COLUMNS.findIndex((col) => col.accessorKey === "valid_from");
      filteredColumns.splice(validFromIndex, 0, ...ONLY_DATE_COLUMNS);
      setColumns(filteredColumns);
    }
  };

  useEffect(() => {
    dispatch(setFilters({ sorting: initialSortingFilter }));
    setHasInitialFiltersBeenApplied(true);
  }, [dispatch]);

  useEffect(() => {
    fetchSavedFields();
    fetchUserId();
  }, []);

  useEffect(() => {
    if (hasInitialFiltersBeenApplied && !isResetting && typeof userId === "number") {
      fetchRequestData();
    }
  }, [filters, hasInitialFiltersBeenApplied, userId]);

  useEffect(() => {
    if (!isInitialRender.current) {
      setIsLoading(true);
      fetchRequestData();
    }
    isInitialRender.current = false;
  }, [activeCategory]);

  useEffect(() => {
    if (!isFieldStatusLoaded || !columnVisibility || Object.keys(columnVisibility).length === 0) {
      return;
    }
    const hasExistingFieldStatus = fieldStatus && Object.keys(fieldStatus.field).length > 0;
    if (hasExistingFieldStatus) {
      const { isEqual, differingFieldVisibility } = compareFieldVisibilityStates(columnVisibility, fieldStatus.field);
      if (!isEqual) {
        saveFieldStatusData(differingFieldVisibility);
      }
    } else {
      const filteredVisibility = filterFalseValues(columnVisibility);
      saveFieldStatusData(filteredVisibility);
    }
  }, [columnVisibility, isFieldStatusLoaded]);

  useEffect(() => {
    if (rowAction) {
      const { type, data } = rowAction;
      switch (type) {
        case DATA_TABLE_ACTION_CLONE:
          navigateToRequestDetail(data.id, LABELS.CLONE);
          break;
        case DATA_TABLE_ACTION_VIEW:
        case DATA_TABLE_ACTION_ROW_CLICK:
          navigateToRequestDetail(data.id, LABELS.VIEW);
          break;
        default:
          console.warn("Unknown action type:", type);
      }
      // Reset action after handling
      dispatch(triggerRowAction(null));
    }
  }, [rowAction, dispatch]);

  return (
    <Box>
      <PageTitle
        title="Request"
        handleBtnNew={() => navigate(`${ROUTE_PATHS.PRICE_SUPPORT_REQUEST}?type=${LABELS.CREATE}`)}
      />
      <ContainerGrid sx={{ justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Grid size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }}>
          <Box sx={{ width: "100%", marginTop: "15px" }}>
            <FormControl fullWidth>
              <InputLabel>Select Category</InputLabel>
              <Select
                value={activeCategory}
                label="Select Category"
                onChange={(e) => setActiveCategory(e.target.value)}>
                {categories.map((opt) => (
                  <MenuItem key={opt.id} value={opt.id}>
                    {opt.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </ContainerGrid>
      {!isLoading && (
        <ContainerGrid>
          <Grid size={12}>
            <DataTable
              data={dataTable}
              totalRows={totalRows}
              columns={columns}
              exportFileName="Request"
              actionButton={{ clone: true, edit: false, view: true }}
            />
          </Grid>
        </ContainerGrid>
      )}
      {isLoading && <Loader />}
    </Box>
  );
};

export default ExternalUser;
