import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { AlertMessage, CommonCheckboxField, CommonTextField, ContainerGrid } from "../index";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { hideModal, resetModalState } from "../../store/slices";
import { LABELS } from "../../utils/constants";
import { checkForChanges, getModalTitle, isModalEditMode, setFormikValues } from "../../utils/helpers";
import { RootState } from "../../store";
import "./Modals.scss";

interface Props {
  handleSubmit: (formData: any) => void;
}

const validationSchema = yup.object({
  entity_name: yup.string().label(`${LABELS.BUSINESS_ENTITY} ${LABELS.NAME}`).required(),
  is_ep: yup.boolean().label(LABELS.OEM_LAYOUT),
  is_obsolete: yup.boolean().label(LABELS.OBSOLETE),
  // is_obsolete: yup.boolean().required("please check").oneOf([true], "please check")
});

const AddOrEditBusinessEntityModal = ({ handleSubmit }: Props) => {
  const { isVisible, modalType, modalData } = useAppSelector((state: RootState) => state.modal);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      entity_name: "",
      is_ep: false,
      is_obsolete: false,
    },
    validationSchema: validationSchema,
    onSubmit: (formData) => {
      const { isChanged, mergedData } = checkForChanges(formData, modalData, dispatch);
      if (isChanged) {
        handleSubmit(mergedData);
        dispatch(hideModal());
        dispatch(resetModalState());
      }
    },
  });

  useEffect(() => {
    if (isModalEditMode(modalType)) setFormikValues(formik, modalData);
  }, [isVisible, modalData]);

  const handleModalExited = () => {
    formik.resetForm();
    dispatch(resetModalState());
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth="sm"
      open={isVisible}
      scroll="body"
      onClose={() => dispatch(hideModal())}
      TransitionProps={{ onExited: handleModalExited }}
      className="cmnModalWrapper addOrEditBusinessEntityModalWrapper">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{getModalTitle("Business Entity", modalType)}</DialogTitle>
        <DialogContent>
          <ContainerGrid disableGutters={true}>
            <AlertMessage show={isModalEditMode(modalType)} />
            <Grid size={12}>
              <CommonTextField
                name="entity_name"
                label={`${LABELS.BUSINESS_ENTITY} ${LABELS.NAME}*`}
                formikProps={{ field: formik.getFieldProps("entity_name"), meta: formik.getFieldMeta("entity_name") }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
              <CommonCheckboxField
                name="is_ep"
                label={`${LABELS.OEM_LAYOUT}`}
                formikProps={{ field: formik.getFieldProps("is_ep"), meta: formik.getFieldMeta("is_ep") }}
              />
            </Grid>
            {isModalEditMode(modalType) && (
              <Grid size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
                <CommonCheckboxField
                  name="is_obsolete"
                  label={`${LABELS.OBSOLETE}`}
                  formikProps={{ field: formik.getFieldProps("is_obsolete"), meta: formik.getFieldMeta("is_obsolete") }}
                />
              </Grid>
            )}
          </ContainerGrid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" className="cancelBtn" onClick={() => dispatch(hideModal())}>
            Cancel
          </Button>
          <Button variant="contained" color="info" type="submit" className="submitBtn">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrEditBusinessEntityModal;
