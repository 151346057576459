import { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import * as yup from "yup";
import { Box, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useFormik } from "formik";
import { ContainerGrid } from "../index";
import { MESSAGES as msg } from "../../utils/constants";

import "./Modals.scss";

interface Props {
  show: boolean;
  handleHide: (x: boolean) => void;
  handleSubmit: (x: any) => void;
}

const validationSchema = yup.object({
  oldPassword: yup.string().min(msg.CHARACTER_LIMIT, msg.PASSWORD_MIN_LENGTH).required(),
  newPassword: yup.string().min(msg.CHARACTER_LIMIT, msg.PASSWORD_MIN_LENGTH).required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords must match")
    .required(),
});

const ChangePasswordModal = ({ show, handleHide, handleSubmit }: Props) => {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [show]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={show}
      onClose={() => handleHide(false)}
      className="cmnModalWrapper changePasswordModalWrapper">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <ContainerGrid disableGutters={true}>
            <Grid size={12}>
              <Box className="commonInputWrap">
                <TextField
                  fullWidth
                  type="password"
                  variant="standard"
                  id="formOldPassword"
                  name="oldPassword"
                  label="Old password*"
                  autoComplete="off"
                  className="commonInputFormControl"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                  helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                />
              </Box>
            </Grid>
            <Grid size={12}>
              <Box className="commonInputWrap">
                <TextField
                  fullWidth
                  type="password"
                  variant="standard"
                  id="formNewPassword"
                  name="newPassword"
                  label="New password*"
                  autoComplete="off"
                  className="commonInputFormControl"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword && formik.errors.newPassword}
                />
              </Box>
            </Grid>
            <Grid size={12}>
              <Box className="commonInputWrap">
                <TextField
                  fullWidth
                  type="password"
                  variant="standard"
                  id="formConfirmPassword"
                  name="confirmPassword"
                  label="Confirm password*"
                  autoComplete="off"
                  className="commonInputFormControl"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
              </Box>
            </Grid>
          </ContainerGrid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            className="cmnBorderBtn yellow cancelBtn widthAuto"
            onClick={() => handleHide(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="info" type="submit" className="cmnFillBtn changeBtn widthAuto">
            Change
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  // return (
  //   <Modal
  //     backdrop="static"
  //     keyboard={false}
  //     show={show}
  //     onHide={() => handleHide(false)}
  //     className="cmnModalWrapper changePassModalWrapper">
  //     <Form onSubmit={formik.handleSubmit}>
  //       <Modal.Header closeButton>
  //         <Modal.Title>Change Password</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <Grid container>
  //           <Grid size={12}>
  //             <Form.Group className="mb-3" controlId="formOldPassword">
  //               <Form.Label>Old password*</Form.Label>
  //               <Form.Control
  //                 type="password"
  //                 name="oldPassword"
  //                 value={formik.values.oldPassword}
  //                 onChange={formik.handleChange}
  //                 isInvalid={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
  //               />
  //               <Form.Control.Feedback type="invalid">{formik.errors.oldPassword}</Form.Control.Feedback>
  //             </Form.Group>
  //           </Grid>
  //           <Grid size={12}>
  //             <Form.Group className="mb-3" controlId="formNewPassword">
  //               <Form.Label>New password*</Form.Label>
  //               <Form.Control
  //                 type="password"
  //                 name="newPassword"
  //                 value={formik.values.newPassword}
  //                 onChange={formik.handleChange}
  //                 isInvalid={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
  //               />
  //               <Form.Control.Feedback type="invalid">{formik.errors.newPassword}</Form.Control.Feedback>
  //             </Form.Group>
  //           </Grid>
  //           <Grid size={12}>
  //             <Form.Group className="mb-3" controlId="formConfirmPassword">
  //               <Form.Label>Confirm password*</Form.Label>
  //               <Form.Control
  //                 type="password"
  //                 name="confirmPassword"
  //                 value={formik.values.confirmPassword}
  //                 onChange={formik.handleChange}
  //                 isInvalid={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
  //               />
  //               <Form.Control.Feedback type="invalid">{formik.errors.confirmPassword}</Form.Control.Feedback>
  //             </Form.Group>
  //           </Grid>
  //         </Grid>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button
  //           variant="secondary"
  //           className="cmnBorderBtn yellow cancelBtn widthAuto"
  //           onClick={() => handleHide(false)}>
  //           Cancel
  //         </Button>
  //         <Button variant="primary" type="submit" className="cmnFillBtn changeBtn widthAuto">
  //           Change
  //         </Button>
  //       </Modal.Footer>
  //     </Form>
  //   </Modal>
  // );
};

export default ChangePasswordModal;
