import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Layout, Loader } from "./components";
import { useAxiosLoader, useUserRole } from "./hooks";
import AppRoutes from "./routes";
import "./App.scss";

const App = () => {
  const [axiosLoading] = useAxiosLoader();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const userRole = useUserRole();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const shouldShowLoader = isAppLoading || axiosLoading;

  return (
    <Box className="appContainer">
      <Layout>
        {shouldShowLoader && <Loader />}
        {!isAppLoading && <AppRoutes />}
      </Layout>
    </Box>
  );
};

export default App;
